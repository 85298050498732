import React from "react"
import style from "./siteLogo.module.scss"
function SiteLogo() {
  return (
    <div className={style.siteLogo}>
      <b>mGamer</b>
    </div>
  )
}

export default SiteLogo
