import React, { useState } from "react"
import ColorfulButton from "../colorfulButton"
import style from "./header.module.scss"
import { GatsbyImage } from "../GatsbyImage"
import SiteLogo from "../sitelogo"
import Sidemenu from "../sidemenu"
import { Link } from "gatsby"
const menu = [
  { text: "Home", path: "/" },
  { text: "Arcade", path: "/arcade/" },
  { text: "Action", path: "/action/" },
  { text: "Puzzle", path: "/puzzle/" },
  { text: "Platform", path: "/platform/" },
  { text: "Racing", path: "/racing/" },
  { text: "Shooting", path: "/shooting/" },
]

function Header({ data }) {
  const [Open, setOpen] = useState(false)
  return (
    <div className={style.headerWrapper}>
      <Sidemenu open={Open} setOpen={setOpen} data={data} />
      <header className={style.header}>
        <div className={style.logoContainer}>
          <span className={style.mobileNavBtn}>
            <GatsbyImage
              onClick={() => setOpen(true)}
              name="menu.svg"
              alt="menu-icon"
            />
          </span>
          <SiteLogo />
        </div>
      </header>
      <header className={style.menuHeader}>
        {menu.map(m => (
          <Link to={m.path}>
            <ColorfulButton text={m.text} />
          </Link>
        ))}
      </header>
    </div>
  )
}

export default Header
