import React from "react"
import { Link } from "gatsby"
import SiteLogo from "../sitelogo"
import { GatsbyImage } from "../GatsbyImage"
import style from "./footer.module.scss"

function Footer({ data }) {
  return (
    <div className={style.footerContainer}>
      <div className={style.links}>
        {data.otherLinks.map(m => (
          <Link
            partiallyActive={m.label === "Home" ? false : true}
            to={m.path}
            className={`${style.menuText}`}
            //activeStyle={activeStyle}
          >
            {m.label}
          </Link>
        ))}
      </div>
      <div className={style.socialLinksContainers}>
        <div className={style.siteLogoContainer}>
          <SiteLogo />
        </div>
        {data.socialMediaLinks.map(m => (
          <a target="_blank" href={m.link} rel="noreferrer">
            <div className={style.socialLinkBox}>
              <GatsbyImage name={m.iconName} alt={m.label} />
              <span>{m.label}</span>
            </div>
          </a>
        ))}
      </div>
      <div className={style.aboutContainer}>
        <h3>About mGamer Games</h3>
        <p>
          mGamer is a online fun playground of HTML 5 games. We have over 60
          games. Our games works on android, ios, desktop devices. We are
          ambitious to integrate more games and fun to our platform.
        </p>
      </div>
    </div>
  )
}

export default Footer
